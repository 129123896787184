<template>
  <div v-if="isLoading" class="tw-flex tw-gap-5 tw-items-center">
    <div
      class="tw-border-gray-300 tw-m-5 tw-h-10 tw-w-10 tw-animate-spin tw-rounded-full tw-border-8 tw-border-t-green-600"
    />
    Loading...
  </div>
  <div v-else-if="error || !caseDetail || caseDetail.status === 'error'">
    Something went wrong or case does not exist.
    {{ error }}
  </div>
  <div v-else class="tw-mb-10">
    <case-status :case-detail="caseDetail" :alarm="alarm" />
    <case-contact-logs :case-detail="caseDetail" />
    <case-description :case-detail="caseDetail" />
    <case-files :case-detail="caseDetail" />
    <case-tickets :case-detail="caseDetail" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useQuery } from '@tanstack/vue-query';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import CaseDescription from '@/components/TicketCases/CaseDescription.vue';
import CaseTickets from '@/components/TicketCases/CaseTickets.vue';
import CaseFiles from '@/components/TicketCases/CaseFiles.vue';
import CaseContactLogs from '@/components/TicketCases/CaseContactLogs.vue';
import CaseStatus from '@/components/TicketCases/CaseStatus.vue';

const route = useRoute();

const id = computed(() => route.params?.id);

const {
  data: caseDetail,
  isLoading,
  error,
} = useQuery({
  queryKey: computed(() => ['caseDetail', String(id.value)]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/tickets/api/v1/cases/${id.value}`);
      if (result.status !== 'OK' || !result) {
        notifications.addNotification({
          message: `Failed to get the case: ${result.statusText}`,
          type: 'danger',
        });
        return;
      }
      return result?.data;
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get the case, ${e.message}`,
        type: 'danger',
      });
      throw new Error('Failed to fetch case data');
    }
  },
  refetchOnWindowFocus: false,
});

const alarmDetail = useQuery({
  queryKey: computed(() => ['alarmDetail', Number(caseDetail.value?.alarm_id)]),
  queryFn: async () => {
    try {
      const result = await fetchData(`/eleena/api/v1/alarms/${Number(caseDetail.value?.alarm_id)}`);
      return result.data;
    } catch (error) {
      notifications.addNotification({
        message: `Failed to get alarm: ${error.message}`,
        type: 'danger',
      });
      throw error;
    }
  },
  refetchOnWindowFocus: false,
  enabled: computed(() => !!caseDetail.value?.alarm_id),
});

const alarm = computed(() => alarmDetail.data.value);
</script>
